
import home from './resources/home.png'
import about from './resources/about.png'
import portfolio from './resources/portfolio.png'
import contact from './resources/contact.png'
import bigClouds from './resources/big_clouds.png'
import bigStars from './resources/big_stars.png'
import cloud from './resources/clouds.png'
import mediumStars from './resources/medium_stars.png'
import smallStars from './resources/small_stars.png'
import cv from './resources/resume.png'
import linkedin from './resources/linkedin.png'
import github from './resources/github.png'
import React from "react";
import { TagCloud } from "@frank-mayer/react-tag-cloud";
import NavItem from "./components/NavItem"
import Cloud from "./components/Cloud"
import LinkBox from "./components/LinkBox";
import LinkButton from "./components/LinkButton";

function App() {
  const resumeLink = "https://drive.google.com/file/d/14EefZiJ3kc3V95zotpQjUAC28RQJl2Cp/view?usp=drivesdk";
  const linkedinLink = "https://www.linkedin.com/in/tim-maksimenko/";
  const githubLink = "https://github.com/tmaksimenko";

  const skills = [
      'Java', 'Python', 'Spring Boot', 'JPA', 'C', 'Assembly', 'GCP', 'Git', 'React', 'SQL', 'Postman',
      'JavaScript'
  ];


  return (
      <div className="App">
          <aside>
              <div className={"testName"}>
                  <NavItem image={home} link={"#home"}/>
                  <NavItem image={about} link={"#about"}/>
                  {/*<NavItem image={portfolio} link={"#portfolio"}/>*/}
                  <NavItem image={contact} link={"#contactPage"}/>
              </div>
              <div className={"bottom-links"}>
                  <LinkBox image={cv} link={resumeLink}/>
                  <LinkBox image={linkedin} link={linkedinLink}/>
                  <LinkBox image={github} link={githubLink}/>
              </div>
          </aside>
          <main>
              <div id={"home"}>
                  <section className={"home"}>

                      <h1>Hi, I'm Tim!</h1>
                      <br/>
                      <p>
                          Backend Software Developer / Computer Engineering Student
                      </p>
                      <div>
                        <LinkButton link={resumeLink} target={"_blank"} text={"Resume"}/>
                        <LinkButton link={"#contactPage"} text={"Contact Me"}/>
                      </div>
                      <img src={bigStars} className={"home-big"}/>

                      <img src={mediumStars} className={"home-stars"}/>
                      <img src={smallStars} className={"home-stars"}/>
                  </section>
              </div>
              <div id={"about"}>
                <section className={"about"}>
                    <h1>About Me</h1>
                    <br/>
                    <br/>
                    <div className={"about-text"}>
                    <p>
                        Hi! I'm a software developer with experience making microservice backends and
                        an interest in cloud architecture and embedded software design.
                    </p>
                    <p>
                        I am currently studying Computer Engineering at Toronto Metropolitan University. I love
                        to learn about programming, software design, and the principles of physics that make computers possible.
                    </p>
                    </div>
                    <TagCloud  className={"tag-cloud"} options={w => ({
                        radius: w.innerWidth / 8,
                        maxSpeed: "fast",
                    })}
                              >
                        {skills}
                    </TagCloud>
                    <img src={mediumStars} className={"about-stars"}/>
                    <img src={smallStars} className={"about-stars"}/>
                </section>
              </div>
              {/*<div id={"portfolio"}>
                  <section>
                      <div className="portfolio">
                          <h1>Portfolio</h1>
                      </div>

                  </section>
              </div>*/}
              <div id={"contactPage"}>
                  <section id={"contactPage"}>
                      <div className={"cloud-parent"}>
                          <div className={"fly-cloud"}>
                              <Cloud image={cloud} right={true}/>
                          </div>
                          <div className={"fly-cloud"}>
                              <Cloud image={cloud} right={false}/>
                          </div>
                          <div className={"cloud-child"}>
                              <img src={bigClouds} className={"cloud-child"}/>
                          </div>
                      </div>
                      <div className={"contact"}>
                          <h1>Contact Me</h1>
                          <p>
                              I'm looking for software engineering opportunities for Summer 2024. <br/>If you are
                              interested or have any questions, don't hesitate to give me<br/> a call or reach out
                              to my email address below:
                          </p>
                          <div className="contact-wrapper">
                              <div className="contact-text">
                                  timothymaksimenko@gmail.com
                                  <hr/>
                                  (647) 885-9795
                              </div>
                          </div>
                      </div>


                  </section>
              </div>
          </main>
      </div>
  );
}

export default App;
