function LinkBox ({image, link}) {
    return (
        <div className={"icon-box"}>
            <a href={link} target="_blank"><img src={image} className={"icons"}/></a>
        </div>


    )
}


export default LinkBox;