import {motion} from "framer-motion"

function Cloud ({ image, right}) {

    return (
        <div className={"box"}>
            <motion.div
                // initial={{ x: right ? "85vw" : 1, scale: 3}}
                initial={{ x: right ? "50vw" : "30vw", scale: 5}}
                transition={{duration: 0.8}}
                whileInView={{ x:  right ? "85vw" : 1, scale: 3}}



            ><img src={image}/></motion.div>
        </div>
    )


}

export default Cloud;