import {motion} from "framer-motion"

function LinkButton ({link, target="_self", text}) {
    return (
        <motion.div className={"link-button"}
                    initial={{opacity: 0.8}}
                    whileHover={{scale: 1.1, opacity: 100}}
                    drag
        >
            <a href={link} target={target}>
                <div className={"link-button-child"}>

                    {text}

        </div>
            </a>
</motion.div>
)
}

export default LinkButton;