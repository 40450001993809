import {motion} from "framer-motion"

function NavItem ({ image, link}) {

    return (
        <div className={"box"}>
            <motion.div
                initial={{opacity: 1, scale: 1}}
                transition={{duration: 0.1}}
                whileHover={{opacity: 1, scale: [0.9, 1.5], rotate: [0, 180, 360]}}
            ><a href={link}><img id={"navimage"} src={image} className={"App-logo"}/></a></motion.div>
        </div>
    )


}

export default NavItem;